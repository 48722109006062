<script setup lang="ts">
import { useDisplay } from 'vuetify'

const props = defineProps({
  code: {
    type: String,
    default: '',
  },
  language: {
    type: String,
    default: null,
  },
  filename: {
    type: String,
    default: 'example.sfz',
  },
  highlights: {
    type: Array as () => number[],
    default: () => [],
  },
  meta: {
    type: String,
    default: null,
  },
  radius: {
    type: String,
    default: '0.5em',
  },
  backgroundColor: {
    type: String,
    default: 'black',
  },
  borderColor: {
    type: String,
    default: '#999',
  },
  class: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
const { mobile } = useDisplay()

const copyToClipboard = async () => {
  try {
    await navigator.clipboard.writeText(props.code)
    // eslint-disable-next-line no-alert
    alert(`${t('Copied to clipboard')}!`)
  } catch (err) {
    console.error(`${t('Failed to copy')}:`, err)
  }
}

const downloadCode = () => {
  const blob = new Blob([props.code], { type: 'text/plain' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = props.filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}
</script>

<template>
  <div class="prosepre-container">
    <div class="prosepre-toolbar">
      <div class="prosepre-filename">
        <span class="d-none d-sm-flex"> {{ props.filename }}</span>
      </div>
      <div style="white-space: nowrap">
        <VBtn size="small" class="mr-1" @click="downloadCode">
          <div><VIcon>mdi-tray-download</VIcon></div>
          <div>{{ $t('Download') }}</div>
        </VBtn>
        <VBtn size="small" @click="copyToClipboard">
          <div><VIcon>mdi-content-copy</VIcon></div>
          <div>{{ $t('Copy') }}</div>
        </VBtn>
      </div>
    </div>
    <div class="prosepre-code" :class="{ 'prosepre-code-mobile-align': mobile }">
      <pre :class="$props.class"><slot /></pre>
    </div>
  </div>
</template>

<style lang="css">
pre code .line {
  display: block;
}
.prosepre-container {
  padding: 0.5em;
  width: 100%;
}
.prosepre-toolbar {
  display: flex;
  justify-content: space-between;
  background-color: v-bind(backgroundColor);
  border-radius: v-bind(radius) v-bind(radius) 0 0;
  border: 1px solid rgba(var(--v-theme-primary), 0.7);
  border-bottom: 0px;
  padding: 0.5em;
}
.prosepre-filename {
  font-size: 1em;
  color: #ccc;
}
.prosepre-code {
  padding: 0em 1em 0em 1em;
  background-color: v-bind(backgroundColor);
  border-radius: 0 0 v-bind(radius) v-bind(radius);
  border: 1px solid rgba(var(--v-theme-primary), 0.7);
  border-top: 0px;
}

.prosepre-code-mobile-align {
  padding: 0;
}

pre {
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  word-wrap: break-word; /* Break long words if necessary */
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Ensure it doesn't overflow its container */
}
</style>
